<template>
  <div class="blue">
      <div class="request-a-callback-form">
        <transition name="fade" mode="out-in">
          <div class="thanksBox" v-show="sent">
            <p class="thanksText">Thanks for contacting us, we will get back to you shortly...</p>
          </div>
        </transition>
        <template v-if="!sent">
          <b-form @submit="onSubmit">
            <b-row class="rowPadMob">
                <b-col md="6" sm="12" class="RightPad">
                    <b-form-input
                    type="text"
                    v-model="names"
                    placeholder="Your Name"
                    />
                </b-col>
                <b-col md="6" sm="12" class="leftPad">
                    <b-form-input
                    type="text"
                    v-model="email"
                    placeholder="Your Email Address"
                    />
                </b-col>
                <b-col md="6" sm="12" class="RightPad">
                    <b-form-input
                    type="text"
                    v-model="phone"
                    placeholder="Your Phone Number"
                    />
                </b-col>
                <b-col md="6" sm="12" class="leftPad">
                    <b-form-select v-model="selected" :options="options" ></b-form-select>
                </b-col>
                <b-col md="12" sm="12">
                    <b-form-textarea
                        v-model="message"
                        rows="11"
                        cols="1"
                        class="form-control enquiryForm"
                        placeholder="Your Enquiry"
                    >
                    </b-form-textarea>
                </b-col>
                <button
                  data-text="submit"
                  type="submit"
                  class="HomepageBtn"
                >
                  Submit
                </button>
            </b-row>
          </b-form>
        </template>
      </div>
  </div>
</template>

<style scoped>
.RightPad.col-md-6.col-6 {
    padding-right: 7px;
}
.leftPad.col-md-6.col-6 {
    padding-left: 7px;
}
.enquiryForm {
    margin-bottom: 70px!important;
    display: block;
}
select.custom-select {
    height: 51.5px;
    border-radius: 0;
    font-size:18px;
    border-radius: 0px;
    margin-bottom: 15px;
    color: #4E4E4E;
    font-style: italic;
}
button.HomepageBtn {
    background-color: #5D8EF0;
    border-color: #5D8EF0!important;
    border-radius: 50px;
    width: 163px;
    height: 31px;
    line-height: 1;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    width: 252px;
    height: 54px;
    margin-top: 65px;
    margin: auto;
    text-align: center;
    display: block;
    font-family: "noto-sans", sans-serif;
}
button.HomepageBtn:hover {
    background-color: #707070;
    border-color: #707070!important;
    color: #fff;
    border: none;
}
::v-deep .btn-danger {
    color: #fff;
    background-color: #7ebd63;
    border-color: #7ebd63!important;
}
textarea.form-control {
    height: 130px;
}
.thanksBox {
    text-align: center;
    border: 5px solid #fff;
    margin-top: 0em;
}
.thanksText {
    font-size: 24px;
    color: #fff;
    /* font-weight: bold; */
    margin-top: 1rem;
}
.blue {
    background-size: cover;
    padding-bottom: 5%;
    /* padding-top: 15%; */
    text-align: left;
}

::v-deep .form-control{
    font-size:18px;
    border-radius: 0px;
    margin-bottom: 15px;
    padding: 1.575rem 0.75rem;
    color: #4E4E4E;
    font-style: italic;
}
.form-control::placeholder {
    color: #4E4E4E;
    opacity: 1;
}

/* Mobile Changes */

@media only screen and (max-width: 480px) {
    .mobPad{
        padding-bottom: 50px;
    }
}

@media only screen and (max-width: 768px) {
  .enquiryForm {
      margin-bottom: 30px !important;
      display: block;
  }
  .rowPadMob {
    padding-left: 30px;
    padding-right: 30px;
  }
  .blue {
    background-size: cover;
    padding-bottom: 20%;
    /* padding-top: 15%; */
    text-align: left;
  }
}
</style>

<script>
import axios from "axios";

export default {
  name: "Form",
  data() {
    return {
        options: [
          { value: null, text: 'Please Select from the dropdown list' },
          { value: 'Book a tour', text: 'Book a tour' },
          { value: 'Private and discreet booking', text: 'Private and discreet booking' },
          { value: 'Corporate membership', text: 'Corporate membership' },
          { value: 'Pay and play', text: 'Pay and play' },
          { value: 'Group membership', text: 'Group membership' },
          { value: 'Golf lessons', text: 'Golf lessons' },
          { value: 'General enquiry', text: 'General enquiry' },
        ],
        sent: false,
        contact: true,
        names: "",
        email: "",
        phone: "",
        selected:null,
        message: "",
    };
  },
  props: ["blue"],
  methods: {
    onSubmit(e) {
      e.preventDefault();
      const formData = new FormData();
      formData.append("names", this.names);
      formData.append("email", this.email);
      formData.append("phone", this.phone);
      formData.append("selected", this.selected);
      formData.append("message", this.message);
      console.log(formData);
      axios
        .post(
          "https://wp-ocssport.ocs-sport.com/golfpark/wp-json/contact-form-7/v1/contact-forms/71/feedback",
          formData
        )
        .then((response) => {
          this.sent = true;
          this.names = "";
          this.email = "";
          this.phone = "";
          this.selected = "";
          this.message = "";
          return response;
        });
    },
  },
};
</script>
